<style>
.link:hover {
  color: #1976d2;
  cursor: pointer;
}
</style>

<template >
  <div align="center">
    <v-toolbar style="box-shadow: none !important">
      <v-toolbar-title> </v-toolbar-title>

      <div style="padding: 35px">
        <br />
        <v-select
          item-text="SERVER_CODE"
          item-value="SERVER_CODE"
          v-model="server_code"
          :items="list_server_code"
          label="Request item for server"
          style="width: 150px"
          v-bind:value="{ id: SERVER_CODE, text: SERVER_CODE }"
          v-on:change="searchMaterial()"
        ></v-select>
      </div>
      <v-spacer></v-spacer>

      <div style="margin-right: 10px; cursor: pointer" v-on:click="listdraft()">
        <v-badge left style="margin-right: 20px">
          <template v-slot:badge>
            <span style="margin-bottom: 3px"
              ><v-icon dark>mdi-basket-outline</v-icon></span
            >
          </template>
          <span style="font-size: 15px" to="/listRegister"
            >{{ totalDraft }} Draft</span
          >
        </v-badge>

        <v-btn depressed color="primary" to="/listRegister"
          >Registration List</v-btn
        >&nbsp;
      </div>
    </v-toolbar>

    <div align="center" style="width: 50%; margin-top: 70px">
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="../../assets/logo-em2.png"
        width="210"
      />
      <br />

      <div v-if="divDesc == true">
        <v-text-field
          style="font-size: 16px ;margin-bottom:-5vh"
          large
          rounded
          
          placeholder="Please type Item Name / Item Description / Part Number / Collaquial Name"
          solo
          height="60"
          v-model="search"
          append-icon="search"
          @click:append="searchMaterial()"
          v-on:keyup.enter="searchMaterial()"
        ></v-text-field>
        
        <v-checkbox v-model="isPartNumber" label="Part Number Only"></v-checkbox>
        <!-- <div v-on:click="linkSearch('PART')" class="link">
                    Click here for searching by Part Number
                </div> -->

        <br />
      </div>

      <!-- <div v-if="divPart==true">
                <v-text-field style="font-size:16px"
                  large
                  rounded
                                           
                  placeholder="Please type Part Number"
                  solo
                  height="60"
                  v-model="search"
                  append-icon="search"
                  @click:append="searchMaterial()"
                  v-on:keyup.enter="searchMaterial()"
                ></v-text-field>

                <div v-on:click="linkSearch('DESC')" class="link">
                    Click here for searching by Item Name or Any Description
                </div>

                <br>
              </div> -->

      <v-progress-circular
        :width="3"
        color="primary"
        indeterminate
        v-if="loading"
      ></v-progress-circular>
    </div>

    <div style="width: 95%; margin-bottom: 80px" v-if="container" align="left">
      <div align="left">
        <v-card>
          <div style="padding: 25px 25px 0px 25px; font-size: 18px">
            Result for <b style="font-style: italic">"{{ reskey }}"</b><br />
          </div>
          <div>
            <v-toolbar style="box-shadow: none !important">
              <!-- <v-checkbox
                            v-model="checkedServer"
                            style="margin-top:20px;margin-left:15px"
                            label="GEMS"
                            value="GEMS"
                            v-on:change="searchMaterial()"
                          ></v-checkbox>

                          <v-checkbox
                            v-model="checkedServer"
                            style="margin-top:20px;margin-left:15px"
                            label="BKES"
                            value="BKES"
                            v-on:change="searchMaterial()"
                          ></v-checkbox>

                          <v-checkbox
                            v-model="checkedServer"
                            style="margin-top:20px;margin-left:15px"
                            label="BC"
                            value="BC"
                            v-on:change="searchMaterial()"
                          ></v-checkbox> -->

              <v-spacer></v-spacer>

              <v-btn
                v-if="divDesc == true || desserts == ''"
                large
                color="primary"
                retain-focus-on-click="true"
                v-on:click="newregister()"
              >
                <span class="mr-2">Register New Item</span>
              </v-btn>
            </v-toolbar>
            <div style="padding: 0px 25px 0px 25px; font-size: 14px">
              <b>Filter Search</b>
            </div>
            <v-row>
              <v-col cols="3">
                <v-autocomplete
                  style="padding: 20px 0px 0px 15px"
                  v-model="checkedServer"
                  :items="uniqueServer"
                  small-chips
                  dense
                  multiple
                  v-on:change="filterMaterial()"
                  label="Server"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  style="padding: 20px 0px 0px 15px"
                  v-model="checkedIncName"
                  :items="uniqueIncName"
                  item-text="INC_NAME"
                  item-value="INC_CODE"
                  small-chips
                  dense
                  multiple
                  v-on:change="filterMaterial()"
                  label="INC Name"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  style="padding: 20px 0px 0px 15px"
                  v-model="checkedCollaqualNames"
                  :items="uniqueCollaqualNames"
                  small-chips
                  dense
                  multiple
                  v-on:change="filterMaterial()"
                  label="Collaqual Name"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  style="padding: 20px 20px 0px 15px"
                  v-model="checkedBrand"
                  :items="uniqueBrand"
                  small-chips
                  dense
                  multiple
                  v-on:change="filterMaterial()"
                  label="Brand"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  style="padding: 0px 20px 0px 15px"
                  v-model="inputDesc"
                  append-icon="mdi-magnify"
                  label="Description"
                  v-on:keyup.enter="filterMaterial()"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <br />

          <template>
            <div v-if="desserts == ''" align="center" style="padding: 30px">
              Data Not Found
            </div>

            <template>
              <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="15"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                class="elevation-1"
                hide-default-header
                v-if="desserts != ''"
              >
                <template v-slot:header="{ props: { headers } }">
                  <thead>
                    <tr>
                      <th
                        v-for="header in headers"
                        :key="header.value"
                         :sortable="header.sortable"
                         @click="changeSort(header.value)"
                         style="cursor: pointer;"
                      >
                        <span v-if="header.text === 'Company / Plant'">
                          <label> Company / Plant</label> <br />
                          <label style="font-style: italic; font-size: 10px; color:red"
                            >Klik company untuk detail plant</label
                          >
                        </span>
                        <span v-else>
                          {{ header.text }}
                          <v-icon small v-if="sortBy === header.value">
                            {{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
                          </v-icon>
                        </span>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item.company="{ item }">
                  <div
                    v-if="item.COMPANY"
                    v-on:click="getPlant(item.MATERIAL_NUMBER)"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span style="cursor: pointer" text small v-on="on">
                          {{ item.COMPANY.substring(0, 40) + "..." }}
                        </span>
                      </template>
                      <div style="width: 180px">{{ item.COMPANY }}</div>
                    </v-tooltip>
                  </div>

                  <div v-if="!item.COMPANY">
                    Tidak ada perusahaan di material ini
                  </div>
                </template>

                <template v-slot:item.action="{ item }">
                  <v-btn
                    v-if="item.SERVER_CODE != server_select"
                    small
                    color="success"
                    v-on:click="extended(item.MATERIAL_NUMBER)"
                  >
                    <span style="font-uppercase: normal">Reg/Copy</span>
                  </v-btn>

                  <v-btn
                    v-if="item.SERVER_CODE == server_select"
                    disabled
                    small
                    color="success"
                  >
                    <span>Available</span>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </template>
        </v-card>
      </div>
    </div>

    <template>
      <v-dialog v-model="dialog_plant" max-height="600px" max-width="800px">
        <v-card>
          <v-card-title> Plant </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Company Name</th>
                    <th class="text-left">Plant Code</th>
                    <th class="text-left">Plant Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in list_detail_plant" :key="item.plant">
                    <td>{{ item.COMPANY_NAME }}</td>
                    <td>{{ item.PLANT }}</td>
                    <td>{{ item.PLANT_NAME }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="dialog_plant = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>


<script>
export default {
  methods: {
    changeSort(column) {
      if (this.sortBy === column) {
        this.sortDesc = !this.sortDesc; // Toggle status pengurutan
      } else {
        this.sortBy = column;
        this.sortDesc = false; // Setel status pengurutan ke menaik saat mengubah kolom pengurutan
      }
    },
    searchMaterial() {
      var self = this;
      this.search = this.search.trim()
      var val = this.search.trim();
      var type_search = this.type_search;
      var url = "";

      if (type_search == "DESC") {
        url = this.base_url + "search/initial_material";
      } else if (type_search == "PART") {
        url = this.base_url + "search/initial_material_partno";
      }

      var server_code = self.server_code;
      if (server_code) {
        self.server_select = server_code;
      }

      self.reskey = val;

      // console.log(server)
      // console.log("val",val)
      if (val) {
        self.loading = true;
        self.container = false;

        this.axios
          .post(url, {
            param: val,
            server: "",
            brand: "",
            incName: "",
            collaqualName: "",
            desc: "",
            isPartNumber:this.isPartNumber
          })
          .then(function (response) {
            const dataCollaqualNames = [
              ...new Set(response.data.map((item) => item.COLLAQUAL_NAME)),
            ];
            const dataIncName = response.data.map((item) => {
              return { INC_NAME: item.INC_NAME, INC_CODE: item.INC_CODE };
            });
            const dataBrand = [
              ...new Set(response.data.map((item) => item.BRAND)),
            ];
            const dataServer = [
              ...new Set(response.data.map((item) => item.SERVER_CODE)),
            ];

            // console.log(dataIncName)
            self.uniqueCollaqualNames = dataCollaqualNames.sort();
            self.uniqueIncName = dataIncName.sort();
            self.uniqueBrand = dataBrand.sort();
            self.uniqueServer = dataServer.sort();
            self.filterSearch = val;
            self.checkedBrand = [];
            self.checkedServer = [];
            self.checkedIncName = [];
            self.checkedCollaqualNames = [];
            self.inputDesc = "";
            self.sortBy = '';
            self.sortDesc = false; 
            // console.log(dataCollaqualNames)
            self.result = response.data;
            self.desserts = response.data;
            self.loading = false;
            self.container = true;
          })
          .catch(function (error) {
            self.result = error;
            self.loading = false;
            self.container = true;
          });
      }
    },
    filterMaterial() {
      var self = this;
      var inputSearch = this.filterSearch;
      var type_search = this.type_search;
      var url = "";

      if (type_search == "DESC") {
        url = this.base_url + "search/initial_material";
      } else if (type_search == "PART") {
        url = this.base_url + "search/initial_material_partno";
      }
      let brand =
        this.checkedBrand.length < 1
          ? ""
          : "'" + this.checkedBrand.join("','") + "'";
      let server =
        this.checkedServer.length < 1
          ? ""
          : "'" + this.checkedServer.join("','") + "'";
      let incName =
        this.checkedIncName.length < 1
          ? ""
          : "'" + this.checkedIncName.join("','") + "'";
      let collaqualName =
        this.checkedCollaqualNames.length < 1
          ? ""
          : "'" + this.checkedCollaqualNames.join("','") + "'";

      if (inputSearch) {
        self.loading = true;
        self.container = false;
        this.axios
          .post(url, {
            param: inputSearch,
            server: server,
            brand: brand,
            incName: incName,
            collaqualName: collaqualName,
            desc: self.inputDesc,
            isPartNumber:this.isPartNumber
          })
          .then(function (response) {
            self.result = response.data;
            self.desserts = response.data;
            self.loading = false;
            self.container = true;
            self.sortBy = '';
            self.sortDesc = false;
          })
          .catch(function (error) {
            self.result = error;
            self.loading = false;
            self.container = true;
          });
      }
    },
    linkSearch(mode = "") {
      if (mode == "DESC") {
        this.divDesc = true;
        this.divPart = false;
        this.type_search = "DESC";
      } else if (mode == "PART") {
        this.divDesc = false;
        this.divPart = true;
        this.type_search = "PART";
      }
      this.container = false;
    },
    extended(id) {
      var server_code = this.server_code;
      this.$router.push({
        path: "/addRegister/" + btoa(server_code) + "/extended/" + btoa(id),
      });
    },
    newregister() {
      var server_code = this.server_code;
      this.$router.push({ path: "/addRegister/" + btoa(server_code) });
    },
    listdraft() {
      this.$router.push({ path: "/listRegister/draft" });
    },
    check: function () {
      console(this.checkedServer);
    },
    getTotalDraft() {
      var self = this;
      var url = self.base_url + "material/count";
      this.axios
        .post(url, {
          user_id: self.storage_user_id,
          status: "DRAFT",
        })
        .then(function (response) {
          self.totalDraft = response.data[0].total;
        });
    },
    getServer() {
      var self = this;
      self.company_id = "";
      self.plant = "";
      self.storage_location = "";
      var url =
        this.base_url + "dropdown/server_setting/" + self.storage_user_id;
      this.axios.get(url).then(function (response) {
        var list_server = JSON.parse(response.data[0].SERVER_ROLE);
        self.list_server_code = list_server;
        self.server_code = list_server[0];
      });
    },
    getPlant(material_number) {
      var self = this;
      var url = this.base_url + "dropdown/plant_detail/" + material_number;
      this.axios.get(url).then(function (response) {
        self.list_detail_plant = response.data;
      });
      self.dialog_plant = true;
    },
  },
  mounted() {
    this.getTotalDraft();
    if (this.storage_role != "REQ") {
      this.$router.push("/login");
    }

    this.getServer();
    this.server_select = this.storage_server;
  },
  watch: {
    search(newVal) {
      this.search = newVal.trim();
    },
    isPartNumber(){
      if(this.search != ''){
        this.searchMaterial()
      }
    }
  },
  data() {
    return {
      checkedServer: [],
      checkedIncName: [],
      checkedCollaqualNames: [],
      checkedBrand: [],
      inputDesc: "",
      loading: false,
      search: "",
      filterSearch: "",
      reskey: "",
      company_name: "PT. BARASENTOSA LESTARI",
      name: "Registration",
      title: "Search Material",
      dialog: false,
      sound: true,
      widgets: false,
      container: false,
      selected: [],
      totalDraft: 0,
      result: [],
      list_server_code: [],
      server_code: "",
      server_select: "",
      dialog_plant: false,
      list_detail_plant: [],
      type_search: "DESC",
      isPartNumber:false,
      divDesc: true,
      divPart: false,
      uniqueCollaqualNames: [],
      uniqueIncName: [],
      uniqueBrand: [],
      uniqueServer: [],
      sortBy: '', // Menyimpan kolom yang digunakan untuk pengurutan
      sortDesc: false, // Menyimpan status pengurutan (menaik atau menurun)
      headers: [
        { text: "Server", value: "SERVER_CODE", sortable: true },
        { text: "Material Number", value: "MATERIAL_NUMBER", sortable: true },
        { text: "INC Code", value: "INC_CODE", sortable: true },
        { text: "Description", value: "DESCRIPTION", sortable: true },
        {
          text: "Additional Info",
          value: "ADDITIONAL_INFORMATION",
          sortable: true,
        },
        { text: "Collaqual Name", value: "COLLAQUAL_NAME", sortable: true },
        { text: "Brand", value: "BRAND", sortable: true },
        { text: "Part No", value: "PART_NO", sortable: true },
        { text: "Company / Plant", value: "company", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      desserts: [],
      //storage.....................................
      storage_user_id: atob(localStorage.getItem("user_id")),
      storage_username: atob(localStorage.getItem("username")),
      storage_fullname: atob(localStorage.getItem("fullname")),
      storage_company_id: atob(localStorage.getItem("company_id")),
      storage_company_name: atob(localStorage.getItem("company_name")),
      storage_role: atob(localStorage.getItem("role")),
      storage_server: atob(localStorage.getItem("server")),
      departement_id: atob(localStorage.getItem("departement_id")),
      departement_desc: atob(localStorage.getItem("departement_desc")),
    };
  },
};
</script>
